<template>
  <b-overlay
    opacity="0.17"
    blur="1rem"
    :show="showOverlay"
    rounded="md"
    variant="secondary"
  >
    <div class="user-select" no-body :title="standardsName">
      <!-- {{ standarddetails.published_dates }} -->
      <!-- <select
          name=""
          id=""
          class="form-control float-right "
          v-model="intversion"
          @change="changelog()"
          style="width:200px;"
        >
          <option
            :value="pdate.int_version"
            v-for="pdate in this.pub_dates"
            :key="pdate.date"
          >
            {{ pdate.date }} version: {{ pdate.version }}
          </option>
        </select> -->

      <div class="card p-1">
        <div
          class="w-100 d-flex flex-row align-items-start justify-content-between"
        >
          <div
            class="d-flex flex-column align-items-start justify-content-start"
          >
            <h3 class="mb-25 font-weight-bold">
              {{ standardsNameVal }}
            </h3>
            <div class="d-flex align-items-center justify-content-start">
              <b-badge variant="primary" v-if="isPrimaryVal" class="mr-1">
                Primary
              </b-badge>

              <b-badge variant="success" v-if="isPublic" class="mr-50"
                >Published</b-badge
              >
              <b-badge
                variant="light-secondary"
                v-if="
                  standarddetails.is_published == 1 &&
                  standarddetails.is_live == 2
                "
                class="mr-50"
                >Inactive</b-badge
              >
              <b-badge
                variant="primary"
                v-if="
                  standarddetails.is_published == 1 &&
                  (standarddetails.is_live == 1 ||
                    standarddetails.is_live == null ||
                    typeof standarddetails.is_live == 'undefined')
                "
                class="mr-50"
                >Active</b-badge
              >
            </div>
          </div>

          <div class="d-flex align-items-center justify-content-end">
            <b-button
              variant="warning"
              size="sm"
              class="mr-1"
              v-b-popover.hover.bottom.v-warning="
                'Deactivating this standard will remove it from the available options during the creation of a new gap assessment.'
              "
              title="Warning"
              v-if="
                standarddetails.is_published == 1 &&
                (standarddetails.is_live == 1 ||
                  standarddetails.is_live == null ||
                  typeof standarddetails.is_live == 'undefined')
              "
              @click="handleWithdrawClick"
            >
              <feather-icon class="mr-25" icon="ChevronsDownIcon" />
              <span class="align-middle">Deactivate</span>
            </b-button>

            <b-button
              variant="primary"
              size="sm"
              class="mr-1"
              v-b-tooltip.hover.bottom.v-primary
              title="Activating this standard will make it available for use when creating a new gap assessment."
              v-if="
                standarddetails.is_published == 1 &&
                standarddetails.is_live == 2
              "
              @click="handleGoLiveClick"
            >
              <feather-icon class="mr-25" icon="ChevronsUpIcon" />
              <span class="align-middle">Activate</span>
            </b-button>

            <button
              @click="gotodraft()"
              class="btn pointer btn-secondary btn-sm mr-1"
              v-if="isPublic"
            >
              <feather-icon class="pointer rotate mr-25" icon="Edit2Icon" />
              <span class="align-middle">Edit</span>
            </button>

            <button
              @click="ImportControlsmodel()"
              class="btn pointer btn-success btn-sm mr-1"
              v-if="!isPublic"
            >
              <feather-icon icon="FilePlusIcon" class="mr-25"></feather-icon>
              Import Controls
            </button>

            <button
              @click="openPrimaryModal()"
              class="btn pointer btn-success btn-sm mr-1"
              v-if="!isPrimaryVal"
              :disabled="
                !this.$store.state.auth.permissions.includes(
                  'standard-setprimary'
                )
              "
            >
              <feather-icon
                v-if="isPrimaryVal"
                class="rotate mr-25"
                icon="CheckCircleIcon"
              />
              <span v-if="isPrimaryVal"> Primary </span>
              <span v-else> Set as Primary </span>
            </button>

            <button
              @click="openPublicModal()"
              class="btn pointer btn-primary btn-sm"
              v-if="!isPublic"
              :disabled="
                !this.$store.state.auth.permissions.includes('standard-publish')
              "
            >
              <span v-if="isPublic"> Published </span>
              <span v-else> Publish </span>
            </button>
          </div>
        </div>

        <div
          class="w-100  rounded p-1 mt-1 d-flex flex-row align-items-center justify-content-end"
        >
          <select
            name=""
            id=""
            class="form-control float-right"
            style="width: 200px"
            placeholder="filter"
            @change="subsetFilter()"
            v-model="subsetId"
          >
            <option value="">Select Subset</option>
            <option :value="sub._id" v-for="sub in subsetlist" :key="sub._id">
              {{ sub.name }}
            </option>
          </select>
        </div>
      </div>

      <!-- <div class="row">
      <div class="col-12 mb-1">
        <select
          name=""
          id=""
          class="form-control float-right"
          style="width: 200px"
          placeholder="filter"
          @change="subsetFilter()"
          v-model="subsetId"
        >
          <option value="">Select Subset</option>
          <option :value="sub._id" v-for="sub in subsetlist" :key="sub._id">
            {{ sub.name }}
          </option>
        </select>
      </div>
    </div>
    <div class="row p-1 mb-1">
      <div
        style="background: #fff; width: 100%"
        class="d-flex align-items-center p-1"
      >
        <div class="col-10">
          <h5>
            {{ standardsNameVal }}
          </h5>
        </div>
        <div class="col-1">
          <div class="d-flex align-items-center">
            <b-badge variant="primary" v-if="isPrimaryVal" class="mr-1">
              Primary
            </b-badge>

            <b-badge variant="success" v-if="isPublic"> Published </b-badge>
          </div>
        </div>
      </div>
    </div> -->

      <!-- <div class="row top-data">
      <div class="">
       
        <button
          @click="gotodraft()"
          class="btn mr-bottom pointer btn-secondary btn-md mr-1"
          v-if="isPublic"
        >
          <feather-icon class="pointer rotate" icon="Edit2Icon" />
          Edit
        </button>
        <button
          @click="ImportControlsmodel()"
          class="btn pointer btn-success btn-md mr-bottom margin-right"
          v-if="!isPublic"
        >
          <feather-icon icon="FilePlusIcon" size="15"></feather-icon>
          Import Controls
        </button>
        <button
          @click="openPrimaryModal()"
          class="btn mr-bottom pointer btn-success btn-md mr-1"
          v-if="!isPrimaryVal"
          :disabled="
            !this.$store.state.auth.permissions.includes('standard-setprimary')
          "
        >
          <feather-icon
            v-if="isPrimaryVal"
            class="rotate"
            icon="CheckCircleIcon"
            size="15"
          />
          <span v-if="isPrimaryVal"> Primary </span>
          <span v-else> Set as Primary </span>
        </button>
      
        <button
          @click="openPublicModal()"
          class="btn mr-bottom pointer btn-primary btn-md"
          v-if="!isPublic"
          :disabled="
            !this.$store.state.auth.permissions.includes('standard-publish')
          "
        >
          <span v-if="isPublic"> Published </span>
          <span v-else> Publish </span>
        </button>
      </div>
    </div> -->

      <!-- <b-card> -->
      <div>
        <Tree
          type="Tree"
          @treeData="recursion"
          @edit="editDettails"
          @saveData="insertDataValnew"
          :node="allStandards"
          :ispublic="isPublic"
          @view="viewDetails"
          :subsetid="subsetId"
        ></Tree>
      </div>
      <div>
        <!-- <Tree
        @treeData="recursion"
        @edit="editDettails"
        @saveData="insertDataValnew"
        :node="allStandards"
        @view="viewDetails"
      ></Tree> -->
      </div>

      <!-- </b-card> -->
      <div v-if="isPublic"></div>
      <b-card class="margin" v-else>
        <span @click="addFamilyFirst()">
          <feather-icon
            class="text-primary pointer rotate"
            icon="PlusCircleIcon"
            size="15"
          />
          Add Control Family
        </span>
      </b-card>
      <b-sidebar
        id="sidebar-changelog"
        bg-variant="white"
        right
        backdrop
        shadow
        v-model="showChangelogsidebar"
      >
        <!-- {{ changeLog }} -->
        <changelog :logs="changeLog" />
      </b-sidebar>
      <b-sidebar
        id="sidebar-viewstandard"
        bg-variant="white"
        right
        backdrop
        shadow
      >
      </b-sidebar>
      <b-sidebar id="sidebar-right" bg-variant="white" right backdrop shadow>
        <editstandards
          :standards="allStandards"
          :editdata="controlFmailyId"
          :closeSidebar="closeSidebar"
          :reloadMasterComponent="load"
          :responseset="responseSet"
        />
      </b-sidebar>

      <span class="mr-btm-5" v-if="showFamilyData">
        <div class="margin">
          <div class="d-flex">
            <input
              v-if="showFmailyInput"
              placeholder="Control family no"
              v-model="controlNumber"
              class="form-control no-Padding margin-right input-sm"
            />
            <input
              v-if="showFmailyInput"
              v-model="controlFamilyname"
              placeholder="Control family name"
              class="form-control input-sm margin-right"
            />
            <div style="min-width: 200px; margin-right: 1rem">
              <!-- <v-select
              v-if="showFmailyInput"
              v-model="subset"
              :reduce="(option) => option.value"
              :options="label_options"
              label="text"
              multiple
              placeholder="Select Subset"
            /> -->
              <treeselect
                v-model="subset"
                :multiple="true"
                :options="label_options"
                v-if="showFmailyInput"
                style="max-width: 200px"
              />
            </div>

            <!-- {{subset}} -->
            <!-- {{subsetlist}} -->
            <b-form-textarea
              v-if="showFmailyInput"
              rows="1"
              placeholder="description"
              v-model="controlFamilyDescription"
              class="jjj no-Padding"
            />
            <div class="d-flex align-items-center">
              <button
                v-if="showFmailyInput"
                class="btn btn-sm"
                @click="closeaddControlFamily"
              >
                cancel
              </button>
              <button
                v-if="showFmailyInput"
                class="btn btn-primary btn-sm"
                @click="addControlFamily(standardId)"
              >
                Add
              </button>
            </div>
          </div>
        </div>
      </span>
      <b-modal
        ref="modal-primary"
        title="Are you sure to set this standard as primary? "
        hide-footer
      >
        <button class="btn btn-success" @click="setAsPrimary()">
          Make it primary
        </button>

        <!-- {{label_options}} -->
      </b-modal>
      <b-modal
        ref="modal-public"
        title="Are you sure to publish this standard? "
        hide-footer
      >
        <button class="btn btn-primary" @click="makePublic()">
          Make it public
        </button>

        <!-- {{label_options}} -->
      </b-modal>
      <b-modal ref="modal-import" title="Import Controls " hide-footer>
        <a
          class="float-right ml-1 mb-1"
          variant="success"
          href="pci-v3_subsets_with_group.csv"
          size="sm"
          download
        >
          Download Sample
        </a>
        <b-form-file
          v-model="uploadfile"
          placeholder="Choose a file or drop it here..."
          drop-placeholder="Drop file here..."
          class="mb-1"
          accept=".csv"
        />
        <!-- {{ JSON.stringify(this.file, name) }} -->
        <button class="btn btn-success btn-sm" @click="UploadControl()">
          Upload
        </button>

        <!-- {{label_options}} -->
      </b-modal>
    </div>
  </b-overlay>
</template>

<script>
import {
  BCard,
  BCardText,
  BLink,
  BButton,
  BSidebar,
  VBToggle,
  BFormTextarea,
  BFormCheckbox,
  BModal,
  VBModal,
  BBadge,
  BFormFile,
  BFormSelect,
  VBTooltip,
  VBPopover,
  BOverlay,
} from "bootstrap-vue";
import Vue from "vue";
import Tree from "./Tree.vue";
import editstandards from "./editstandards.vue";
import BCardCode from "@core/components/b-card-code/BCardCode.vue";
import ToastificationContent from "@core/components/toastification/ToastificationContent.vue";
import changelog from "./components/ChangeLog.vue";
import vSelect from "vue-select";
import { required } from "@validations";
import { ValidationProvider, ValidationObserver } from "vee-validate";
import Treeselect from "@riophae/vue-treeselect";
// import the styles
import "@riophae/vue-treeselect/dist/vue-treeselect.css";
import ResponseMixins from "../mixins/ResponseMixins";
export default {
  data: () => ({
    showOverlay: false,
    allStandards: [],
    allControlsFamily: [],
    allControls: [],
    allControlsGroup: [],
    filteredFamily: [],
    controlNumber: "",
    controlFamilyname: "",
    controlFamilyDescription: "",
    familyGroupName: "",
    familyGroupDescription: "",
    standardId: "",
    controlFmailyId: {},
    hideSlidebar: true,
    hideInputVal: true,
    addFamily: false,
    hideFamily: false,
    showFamilyData: false,
    showFmailyInput: false,
    standardsName: "",
    standardsNameVal: "",
    isPrimaryVal: false,
    tableFamily: {},
    isPrimary: false,
    isPublic: false,
    uploadfile: "",
    standarddetails: [],
    showChangelogsidebar: false,
    intversion: "",
    pub_dates: [],
    changeLog: [],
    file: null,
    name: null,
    subsetlist: [],
    subset: [],
    label_options: [],
    subsetId: "",
    responseSet: [],
    required,
  }),
  mixins: [ResponseMixins],
  components: {
    BCard,
    BCardText,
    BLink,
    Tree,
    BButton,
    BSidebar,
    editstandards,
    BCardCode,
    BFormTextarea,
    BFormCheckbox,
    BModal,
    VBModal,
    BBadge,
    BFormFile,
    changelog,
    vSelect,
    Treeselect,
    ValidationProvider,
    ValidationObserver,
    BOverlay,
  },
  directives: {
    "b-toggle": VBToggle,
    "b-modal": VBModal,
    "b-popover": VBPopover,
    "b-tooltip": VBTooltip,
  },

  watch: {
    $route(to, from) {
      this.standardId = this.$route.params.id;
      this.standardsName = this.$route.meta.pageTitle;
      // this.getStandardsFamily(this.standardId);
    },
  },

  mounted() {
    this.load();
  },
  methods: {
    handleWithdrawClick() {
      this.showOverlay = true;
      const options = {
        method: "POST",
        headers: { "content-type": "application/json" },
        url:
          process.env.VUE_APP_ONECSF_API_URL +
          `/standards/${this.$route.params.id}/withdraw`,
      };
      this.$http(options)
        .then((res) => {
          this.handleResponse(res);
          this.load();
        })
        .catch((err) => {
          console.log(err);
          this.handleError(err);
        })
        .finally(() => {
          this.showOverlay = false;
        });
    },

    handleGoLiveClick() {
      this.showOverlay = true;
      const options = {
        method: "POST",
        headers: { "content-type": "application/json" },
        url:
          process.env.VUE_APP_ONECSF_API_URL +
          `/standards/${this.$route.params.id}/go-live`,
      };
      this.$http(options)
        .then((res) => {
          this.handleResponse(res);
          this.load();
        })
        .catch((err) => {
          console.log(err);
          this.handleError(err);
        })
        .finally(() => {
          this.showOverlay = false;
        });
    },

    closeaddControlFamily() {
      this.showFamilyData = false;
    },
    subsetFilter() {
      const options = {
        method: "GET",
        headers: { "content-type": "application/json" },
        url:
          process.env.VUE_APP_ONECSF_API_URL +
          `/standards/${this.standardId}?subset_id=${this.subsetId}`,
      };
      this.$http(options)
        .then((res) => {
          this.standardsNameVal = res.data.data.standard.name;
          this.isPrimaryVal =
            res.data.data.standard.is_primary == "1" ? true : false;
          this.isPublic =
            res.data.data.standard.is_published == "1" ? true : false;
          this.standarddetails = res.data.data.standard;

          this.allStandards = res.data.data.families;
          this.pub_dates = this.standarddetails.published_dates;
        })
        .catch((err) => {
          console.log(err);
        });
    },
    getSubset(id) {
      const options = {
        method: "GET",
        headers: {
          "content-type": "application/json",
          Accept: "application/json",
        },

        url: process.env.VUE_APP_ONECSF_API_URL + `/standard_subset/${id}`,
      };

      this.$http(options)
        .then((res) => {
          this.subsetlist = res.data.data;
          this.label_options = [];
          for (let [key, value] of Object.entries(this.subsetlist)) {
            let a = {
              id: value._id,
              label: value.name,
            };
            // console.log(res.data[key].profile.organization)
            this.label_options.push(a);
          }
        })
        .catch((err) => {});
    },
    changelog() {
      this.showChangelogsidebar = true;
      this.getStandardLogs();
    },
    getStandardLogs() {
      const options = {
        method: "GET",
        headers: { "content-type": "application/json" },
        url:
          process.env.VUE_APP_ONECSF_API_URL +
          `/changelog/${this.standardId}?int_version=${this.intversion}`,
      };
      this.$http(options)
        .then((res) => {
          this.changeLog = res.data.data;
        })
        .catch((err) => {
          console.log(err);
        });
    },
    gotodraft() {
      this.$router.push({
        name: "Standarddraft",
        params: { id: this.standardId },
      });
    },
    ImportControlsmodel() {
      this.$refs["modal-import"].show();
    },
    openPrimaryModal() {
      this.$refs["modal-primary"].show();
    },
    openPublicModal() {
      this.$refs["modal-public"].show();
    },
    load() {
      this.bindEvents();
      this.standardId = this.$route.params.id;
      this.getStandard(this.standardId);
      this.getSubset(this.standardId);
      // this.getStandardsFamily(this.standardId);
    },
    UploadControl() {
      const formData = new FormData();
      formData.append("controls_list", this.uploadfile);
      formData.append("standard_id", this.standardId);
      // const data = {
      //   standard_id: this.standardId,
      //   controls_list: this.uploadfile
      // };
      const options = {
        method: "POST",
        data: formData,
        headers: { "content-type": "application/json" },
        url: process.env.VUE_APP_ONECSF_API_URL + `/fileupload/standard`,
      };
      this.$http(options)
        .then((res) => {
          this.getStandard(this.standardId);
          this.$router.go();
          this.uploadfile = null;
          this.$refs["modal-import"].hide();
          if (res.data.status == "error") {
            this.$toast({
              component: ToastificationContent,
              props: {
                title: res.data.message,
                icon: "EditIcon",
                variant: "danger",
              },
            });
          } else {
            this.$toast({
              component: ToastificationContent,
              props: {
                title: res.data.message,
                icon: "EditIcon",
                variant: "success",
              },
            });
          }
        })
        .catch((err) => {
          console.log(err);
        });
    },
    makePublic() {
      const options = {
        method: "POST",
        headers: { "content-type": "application/json" },
        url:
          process.env.VUE_APP_ONECSF_API_URL +
          `/standards/${this.standardId}/publish`,
      };
      this.$http(options)
        .then((res) => {
          // this.getStandards();
          // this.sliderVal = false;
          this.getStandard(this.standardId);
          this.$refs["modal-public"].hide();
          if (res.data.status == "error") {
            this.$toast({
              component: ToastificationContent,
              props: {
                title: res.data.message,
                icon: "EditIcon",
                variant: "danger",
              },
            });
          } else {
            this.$toast({
              component: ToastificationContent,
              props: {
                title: res.data.message,
                icon: "EditIcon",
                variant: "success",
              },
            });
          }
          console.log(res.data);
        })
        .catch((err) => {
          console.log(err);
        });
    },
    setAsPrimary() {
      // alert("hello")
      // if (!this.isPrimary) {
      //   this.isPrimary = true;
      // } else {
      //   this.isPrimary = false;
      // }
      // const data = {
      //   is_primary: this.isPrimary ? 1 : 0,
      // };
      const options = {
        method: "POST",
        headers: { "content-type": "application/json" },
        url:
          process.env.VUE_APP_ONECSF_API_URL +
          `/standards/${this.standardId}/primary`,
      };
      this.$http(options)
        .then((res) => {
          // this.getStandards();
          // this.sliderVal = false;
          this.getStandard(this.standardId);
          this.$refs["modal-primary"].hide();
          if (res.data.status == "error") {
            this.$toast({
              component: ToastificationContent,
              props: {
                title: res.message,
                icon: "EditIcon",
                variant: "danger",
              },
            });
          } else {
            this.$toast({
              component: ToastificationContent,
              props: {
                title: res.data.message,
                icon: "EditIcon",
                variant: "success",
              },
            });
          }
          console.log(res.data);
        })
        .catch((err) => {
          this.$toast({
            component: ToastificationContent,
            props: {
              title: err.data.message,
              icon: "EditIcon",
              variant: "danger",
            },
          });
          console.log(err);
        });
    },

    listViewRouter() {
      this.$router.push({ name: "Treelist", id: this.standardId });
    },
    closeSidebar: function () {
      this.$root.$emit("bv::toggle::collapse", "sidebar-right");
    },
    openSidebar: function () {
      this.$root.$emit("bv::toggle::collapse", "sidebar-right");
    },
    openSidebarviews: function () {
      this.$root.$emit("bv::toggle::collapse", "sidebar-viewstandard");
    },
    getStandard(id) {
      const options = {
        method: "GET",
        headers: { "content-type": "application/json" },
        url: process.env.VUE_APP_ONECSF_API_URL + `/standards/${id}`,
      };
      this.$http(options)
        .then((res) => {
          this.standardsNameVal = res.data.data.standard.name;
          this.isPrimaryVal =
            res.data.data.standard.is_primary == "1" ? true : false;
          this.isPublic =
            res.data.data.standard.is_published == "1" ? true : false;
          this.standarddetails = res.data.data.standard;
          this.responseSet = res.data.data.standard.response_set;
          this.allStandards = res.data.data.families;
          this.pub_dates = this.standarddetails.published_dates;
        })
        .catch((err) => {
          console.log(err);
        });
    },
    addFamilyFirst() {
      const stId = this.$route.params.id;
      if (stId) {
        this.showFmailyInput = !this.showFmailyInput;
        this.showFamilyData = true;
      }
    },
    bindEvents() {
      this.$root.$off("delete").$on("delete", (data) => {
        // alert(JSON.stringify(data));
        if (!data.control_family_id) {
          this.deteteFamily(data);
        } else if (data.type == "Control" || data.type == "Group") {
          this.deteteControl(data, data.index);
        } else {
          this.deleteSubControl(data, data.index);
        }
      });
      this.$root.$on("tableFamily", (data) => {
        this.addControlFamily(this.standardId, "tableFamilydata", data);
      });
      this.$root.$off("addControl").$on("addControl", (data) => {
        this.insertControl(data);
      });
      this.$root.$off("addSubControl").$on("addSubControl", (data) => {
        this.addSubControl(data);
      });
    },

    addSubControl(data) {
      // alert(data)
      console.log(data);
      let self = this;
      const body = {
        control_no: data.control_no,
        name: data.name,
        description: data.description,
        standard_id: data.standard_id,
        type: data.type,
        control_family_id: data.control_family_id,
        parent_id: data.parent_id,
        subset_id: data.subset_id,
      };
      const options = {
        method: "POST",
        data: body,
        headers: { "content-type": "application/json" },
        url: process.env.VUE_APP_ONECSF_API_URL + `/controls`,
      };
      this.$http(options)
        .then((res) => {
          if (res.data.status == "error") {
            this.$toast({
              component: ToastificationContent,
              props: {
                title: "Something went wrong try again",
                icon: "EditIcon",
                variant: "danger",
              },
            });
          } else {
            this.$toast({
              component: ToastificationContent,
              props: {
                title: "Subcontrol created !",
                icon: "EditIcon",
                variant: "success",
              },
            });
          }
          const splitIndex = data.indexValue.split("_");
          let result = {};
          for (let i = 0; i < splitIndex.length; i++) {
            if (i == 0) {
              result = self.allStandards[splitIndex[i]];
            } else {
              result = result.child[splitIndex[i]];
            }
          }
          Vue.set(result, "subcontrols", res.data.data.subcontrols);
          Vue.set(result, "child", res.data.data.subcontrols);
          // this.$router.go()
          this.$root.$emit("hideinput", { type: "subcontrol", id: data.id });
          this.getStandard(this.standardId);
          //  this.$router.go()
        })
        .catch((err) => {
          console.log(err);
        });
    },
    hideInput(id, data) {
      this.hideInputVal = false;
      let hidedata = {
        inputKeyVal: this.hideInputVal,
        inputId: id,
        insertData: data,
      };
      this.$root.$emit("hideinput", hidedata);
    },

    editDettails(editObj) {
      /////////////////////////////////(99999//////////////////////////////
      // alert(JSON.stringify(editObj))
      this.openSidebar();
      this.controlFmailyId = editObj;
      // this.disablevalue = dis
      // this.$root.$emit('')
      this.$root.$emit("editValdata", editObj, this.isPublic);
    },
    viewDetails(editObj) {
      this.openSidebarviews();
      //  alert(editObj);
      this.controlFmailyId = editObj;
      this.$root.$emit("editValdata", editObj);
    },

    getControl(id, index) {
      const options = {
        method: "GET",
        headers: { "content-type": "application/json" },
        url: process.env.VUE_APP_ONECSF_API_URL + `/controls/${id}`,
      };
      this.$http(options)
        .then((res) => {
          const coppyData = this.allStandards;
          const val = coppyData.filter(
            (ele) => ele._id == res.data.data.control.control_family_id
          );
          const childData = val[0].child.filter(
            (ele) => ele.name == res.data.data.control.name
          );

          Vue.set(childData[0], "child", res.data.data.subcontrols);
        })
        .catch((err) => {
          console.log(err);
        });
    },
    deteteFamily(data, index) {
      const options = {
        method: "DELETE",
        headers: { "content-type": "application/json" },
        url: process.env.VUE_APP_ONECSF_API_URL + `/cl_families/${data._id}`,
      };
      this.$http(options)
        .then((res) => {
          this.getStandardsFamily(data.standard_id);

          this.allStandards = res.data.data;
        })
        .catch((err) => {
          console.log(err);
        });
      // setTimeout(this.showTeamList, 5000);
    },
    deteteControl(data, index) {
      const options = {
        method: "DELETE",
        headers: { "content-type": "application/json" },
        url: process.env.VUE_APP_ONECSF_API_URL + `/controls/${data._id}`,
      };
      this.$http(options)
        .then((res) => {
          if (data.type == "Control") {
            this.getControl(data.parent_id, index);
          } else if (data.type == "Group") {
            this.getFamily(data.control_family_id, index);
          }
          this.getStandard(this.standardId);
        })
        .catch((err) => {
          console.log(err);
        });
    },
    deleteSubControl(data, index) {
      const options = {
        method: "DELETE",
        headers: { "content-type": "application/json" },
        url: process.env.VUE_APP_ONECSF_API_URL + `/controls/${data._id}`,
      };
      this.$http(options)
        .then((res) => {
          if (data.type == "Control") {
            this.getControl(data.parent_id, index);
          } else if (data.type == "Group") {
            this.getFamily(data.control_family_id, index);
          }
          this.getStandard(this.standardId);
        })
        .catch((err) => {
          console.log(err);
        });
    },
    getFamily(id) {
      const options = {
        method: "GET",
        headers: { "content-type": "application/json" },
        url: process.env.VUE_APP_ONECSF_API_URL + `/cl_families/${id}`,
      };
      this.$http(options)
        .then((res) => {
          const coppyData = this.allStandards;
          const val = coppyData.filter(
            (ele) => ele._id == res.data.data.control._id
          );
          Vue.set(val[0], "child", res.data.data.subcontrols);
        })
        .catch((err) => {
          console.log(err);
        });
    },
    bindValue(val, index) {
      const splitIndex = index.split("_");
      let result = {};
      for (let i = 0; i < splitIndex.length; i++) {
        if (i == 0) {
          result = this.allStandards[splitIndex[i]];
        } else {
          result = result.child[splitIndex[i]];
        }
      }
      if (!Array.isArray(val)) {
        if (!result.child) {
          result.child = [];
        }
        let duplicate = [];
        duplicate = result.child;
        duplicate.push(val);
        val = duplicate;
      }
      Vue.set(result, "child", val);
    },

    recursion(data) {
      const index = data.e.target.dataset.index;
      let controlName;
      let block = false;
      let url = process.env.VUE_APP_ONECSF_API_URL;
      // if (Object.keys(data.value).includes("subcontrols")) {
      //   data.value.subcontrols.forEach((ele, key) => {
      //     ele.type = "Subcontrol";
      //     ele.control_id = data.value._id;
      //     ele.index = key;
      //   });
      //   data.value.childType = "Subcontrol";
      //   Vue.set(data.value, "child", data.value.subcontrols);
      //   block = true;
      // } else

      if (
        Object.keys(data.value).includes("control_family_id") &&
        Object.keys(data.value).includes("standard_id")
      ) {
        url += `/controls`;
        if (data.value.type == "Control") {
          data.value.childType = "Sub control";
        } else {
          data.value.childType = "Control";
        }
      } else if (
        Object.keys(data.value).includes("standard_id") &&
        !Object.keys(data.value).includes("control_family_id")
      ) {
        url += `/cl_families`;
        if (data.value.type == null) {
          data.value.childType = "Controls / Group";
        }
      }
      if (!block) {
        const options = {
          method: "GET",
          headers: {
            "content-type": "application/json",
          },
          url: url + `/${data.value._id}?subset_id=${this.subsetId}`,
        };
        this.$http(options)
          .then((res) => {
            if (
              res.data.data.type == null &&
              !res.data.data.subcontrols.length
            ) {
              data.value.childType = null;
            } else if (res.data.data.type == "Group") {
              data.value.childType = "Group";
            } else if (res.data.data.type == "Control") {
              data.value.childType = "Control";
            } else {
              data.value.childType = "Subcontrol";
            }
            this.bindValue(res.data.data.subcontrols, index);
          })
          .catch((err) => {
            console.log(err);
          });
      }
    },
    insertDataValnew(data) {
      this.insertControl(data);
    },
    getStandardsFamily(id) {
      const options = {
        method: "GET",
        headers: { "content-type": "application/json" },
        url:
          process.env.VUE_APP_ONECSF_API_URL + `/cl_families?standard_id=${id}`,
      };
      this.$http(options)
        .then((res) => {
          if (!res.data.data.data.length) {
            this.addFamily = true;
            let addObj = {
              id: id,
              showValue: this.addFamily,
            };
            this.$root.$emit("addFamilyaData", addObj);
          } else {
            this.allStandards = res.data.data.data.sort((a, b) =>
              a.control_no > b.control_no
                ? 1
                : b.control_no > a.control_no
                ? -1
                : 0
            );
          }
        })
        .catch((err) => {
          console.log(err);
        });
    },
    insertControl(control) {
      // control.insertData.selected = control.datavalue.type == 'Group' ? control.datavalue.childType : control.insertData.selected
      // if (control.insertData.selected != 'Control / Group') {
      console.log(JSON.stringify(control.subset_id) + "from standard subset");
      let dataParentId = "";
      let selectedType = "";
      if (!control.insertData.selected) {
        selectedType = control.datavalue.childType;
      }
      if (control.datavalue.childType != "Control") {
        dataParentId = "0";
      } else {
        if (control.datavalue.type == "Group") {
          dataParentId = control.datavalue._id;
        } else {
          dataParentId = "0";
        }
      }
      const body = {
        name: control.insertData.dataName,
        description: control.insertData.dataDescription,
        standard_id: control.datavalue.standard_id,
        control_family_id:
          control.datavalue.childType == "Control" &&
          Object.keys(control.datavalue).includes("control_family_id")
            ? control.datavalue.control_family_id
            : control.datavalue._id,
        control_no: control.insertData.dataControlNumber,
        parent_id: dataParentId,
        onecsf_control_id: "",
        type: control.insertData.selected
          ? control.insertData.selected
          : selectedType,
        subset_id: control.subset_id,
      };
      const options = {
        method: "POST",
        data: body,
        headers: { "content-type": "application/json" },
        url: process.env.VUE_APP_ONECSF_API_URL + `/controls`,
      };
      const coppyData = this.allStandards;
      this.$http(options)
        .then((res) => {
          if (res.data.status == "error") {
            this.$toast({
              component: ToastificationContent,
              props: {
                title: "Something went wrong try again",
                icon: "EditIcon",
                variant: "danger",
              },
            });
          } else {
            this.$toast({
              component: ToastificationContent,
              props: {
                title: "Control created !",
                icon: "EditIcon",
                variant: "success",
              },
            });
          }
          this.getStandard(this.standardId);
          this.$router.go();
          control.insertData.dataName = "";
          control.insertData.dataDescription = "";
          control.insertData.dataControlNumber = "";
          if (control.datavalue.childType == "Group") {
            coppyData[control.indexValue].child.push(res.data.data);
          } else {
            if (
              control.datavalue.childType == "Control" &&
              !Object.keys(control.datavalue).includes("control_family_id")
            ) {
              coppyData[control.indexValue].child.push(res.data.data);
            } else if (control.datavalue.childType == "Control / Group") {
              coppyData[control.indexValue].child.push(res.data.data);
            } else {
              this.bindValue(res.data.data, control.indexValue);
            }
          }
          if (res.data.data.type == "Group") {
            control.datavalue.childType = "Group";
          } else {
            control.datavalue.childType = "Control";
          }
          this.hideInput(control.datavalue._id);
          console.log(res);
        })
        .catch((err) => {
          console.log(err);
        });
      // }
    },
    addControlFamily(id, type, data) {
      let body = {};
      if (type == "tableFamilydata") {
        body = {
          name: data.data.dataName,
          description: data.data.dataDescription,
          standard_id: id,
          control_no: data.data.dataControlNumber,
          subset_id: this.subset,
        };
      } else {
        body = {
          name: this.controlFamilyname,
          description: this.controlFamilyDescription,
          standard_id: id,
          control_no: this.controlNumber,
          subset_id: this.subset,
        };
      }
      const options = {
        method: "POST",
        data: body,
        headers: { "content-type": "application/json" },
        url: process.env.VUE_APP_ONECSF_API_URL + `/cl_families`,
      };
      const coppyDataFM = this.allStandards;
      this.$http(options)
        .then((res) => {
          this.getStandardsFamily(id);
          (this.controlFamilyname = ""),
            (this.controlFamilyDescription = ""),
            (this.controlNumber = "");

          this.showFmailyInput = false;
        })
        .catch((err) => {
          console.log(err);
        });
    },
  },
};
</script>

<style>
.margin {
  margin-left: 35px;
}
.mr-bottom {
  margin-bottom: 10px;
}
.top-data {
  display: flex;
  justify-content: end;
  align-items: center;
}
.user-select {
  user-select: none;
}
.text-primary-green {
  margin-left: 10px;
}
</style>
