<template>
  <div>
    <div class="margin content-body">
      <!-- {{conid}} -->
      <!-- {{node}} -->

      <div v-if="type == 'Tree'">
        <li
          v-for="(allData, indexa) in node"
          :class="[
            {
              'padding-20 mr-btm-20':
                allData.standard_id && !allData.control_family_id,
            },
            ' tree node-tree card card-congratulation-medal mr-btm-5',
          ]"
          :key="allData._id"
        >
          <div>
            <!-- {{node}} -->
            <!-- {{allData}} -->

            <div class="flex space-last">
              <div
                class="font-weight font-15"
                :data-index="String(index) ? index + '_' + indexa : indexa"
                @click.stop="()=>{
                  if(allData.type)
                  // getStandardClick(allData, $event)
                  redirectToControlDetail(allData._id)
                // else{
                // }
                }
                "
                style="width: 90%"
              >
              
                <feather-icon
                  v-if="allData.standard_id && !allData.control_family_id"
                  class="text-primary rotate mr-1"
                  icon="FolderIcon"
                  size="15"
                />
                <feather-icon
                  v-if="
                    allData.control_family_id &&
                    allData.standard_id &&
                    allData.type == 'Group'
                  "
                  class="text-primary rotate mr-1"
                  icon="PackageIcon"
                  size="15"
                />
                <feather-icon
                  v-if="
                    allData.control_family_id &&
                    allData.standard_id &&
                    allData.type == 'Control'
                  "
                  class="text-primary rotate mr-1"
                  icon="DiscIcon"
                  size="15"
                />
                <feather-icon
                  v-if="
                    allData.control_family_id &&
                    allData.standard_id &&
                    allData.type == 'Subcontrol'
                  "
                  class="text-primary rotate mr-1"
                  icon="CheckCircleIcon"
                  size="15"
                />
                <!-- <span v-if="allData.no"> {{ allData.no }}</span> -->
                {{ allData.control_no }} : {{ allData.name }} 


                <!-- {{allData}} -->
                <!-- {{ispublic}} -->
              </div>
              <feather-icon
                  v-if="allData.type !== 'Subcontrol'&&(!allData.child||allData.child.length)"
                  :data-index="String(index) ? index + '_' + indexa : indexa"
                  @click.stop="getStandardClick(allData, $event)"
                  style="margin-top: 5px"
                  class=" rotate"
                  icon="ChevronDownIcon"
                  size="15"
                />
              <div v-if="ispublic">
                <span @click="showData(allData)" class="icon-box-new">
                  <feather-icon
                    class="pointer  rotate"
                    icon="EyeIcon"
                    size="20"
                  />
                </span>
              </div>
              <div v-else>
                <span @click="editData(allData)" class="icon-box-new">
                  <feather-icon
                    class="pointer  rotate"
                    icon="Edit2Icon"
                    size="20"
                  />
                </span>
                <span
                  @click.stop="
                    deleteData(
                      allData,
                      String(index) ? index + '_' + indexa : indexa
                    )
                  "
                  class="icon-box-new"
                >
                  <feather-icon
                    class="text-danger pointer rotate"
                    icon="Trash2Icon"
                    size="20"
                  />
                </span>
              </div>
            </div>
          </div>
          <span
            v-if="allData.description"
            class="description-text"
            style="width: 80%"
            >{{ allData.description }}</span
          >
          <template
            v-if="allData.child && allData.child.length && type != 'table'"
          >
            <!-- {{allData}} -->
            <Tree
              type="Tree"
              @saveData="addData"
              @edit="editData"
              @delete="deleteData"
              @treeData="getStandardClick"
              :index="String(index) ? index + '_' + indexa : indexa"
              :node="allData.child"
              :ispublic="ispublic"
            >
            </Tree>
          </template>

          <div v-if="inputShow[allData._id]" class="flex space-last center">
            <div class="flex-display aPlign-center full-width mr-botm-10">
              <input
                v-if="inputShow[allData._id]"
                v-model="insertValue.dataControlNumber"
                :placeholder="
                  allData.childType == 'Control'
                    ? 'Control no'
                    : allData.childType == 'Group'
                    ? 'Group no'
                    : allData.childType == 'Subcontrol'
                    ? 'Subcontrol no'
                    : 'Control no / Group no'
                "
                class="form-control margin input-sm flex margin-right"
              />
              <input
                v-if="inputShow[allData._id]"
                v-model="insertValue.dataName"
                :placeholder="
                  allData.childType == 'Control'
                    ? 'Control name'
                    : allData.childType == 'Group'
                    ? 'Group name'
                    : allData.childType == 'Subcontrol'
                    ? 'Subcontrol name'
                    : 'name'
                "
                class="form-control input-sm flex margin-right"
              />
              <!--Subset-->
              <div style="min-width: 200px; margin-right: 1rem">
                <!-- <v-select
                  v-model="subset"
                  :reduce="(option) => option.value"
                  :options="label_options"
                  label="text"
                  multiple
                  placeholder="Select Subset"
                /> -->
                <treeselect
                  v-model="subset"
                  :multiple="true"
                  :options="label_options"
                  style="max-width: 200px"
                />
              </div>
              <!-- {{subset}} -->
              <!--Subset End-->
              <b-form-textarea
                v-if="inputShow[allData._id]"
                rows="1"
                v-model="insertValue.dataDescription"
                placeholder="description"
                class="margin-right no-Padding"
              />
              <b-form-select
                v-if="
                  inputShow[allData._id] &&
                  !allData.child.length &&
                  allData.childType != 'Control' &&
                  allData.childType != 'Group' &&
                  allData.childType != 'Subcontrol'
                "
                v-model="insertValue.selected"
                v-bind:_="insertValue.selected"
                :options="options"
              />
            </div>
            <div class="flex">
              <button
                v-if="inputShow[allData._id]"
                class="btn pointer btn-sm"
                @click="cancelDetails(allData._id, allData)"
              >
                cancel
              </button>
              <button
                v-if="inputShow[allData._id]"
                class="btn pointer btn-primary btn-sm margin-right"
                @click.stop="
                  addData(
                    allData,
                    String(index) ? index + '_' + indexa : indexa
                  )
                "
              >
                Add
              </button>
            </div>
          </div>
          <span v-if="ispublic"></span>
          <span v-else>
            <span
              @click="addControlKey(allData._id, allData)"
              class="mr-botm margin flex-display"
              v-if="allData.child && allData.childType != null"
            >
              <!-- {{allData}} -->
              <feather-icon
                class="text-primary pointer mr-1 rotate"
                icon="PlusCircleIcon"
                size="15"
                v-if="allData.type != 'Subcontrol'"
              />

              <div v-if="allData.type != 'Subcontrol'">
                {{ `Add ${allData ? allData.childType : ""}` }}
              </div>
            </span>
          </span>
          <!--Where Child is not present -->
          <span v-if="ispublic"></span>
          <span v-else>
            <span
              @click="addControlKey(allData._id, allData)"
              class="mr-botm margin flex-display"
              v-if="allData.child && allData.childType == null"
            >
              <!-- {{allData.child}} -->
              <feather-icon
                class="text-primary pointer mr-1 rotate"
                icon="PlusCircleIcon"
                size="15"
              />

              <div>
                {{
                  `Add ${
                    allData
                      ? allData.childType == null
                        ? "Control/Group"
                        : allData.childType
                      : ""
                  }`
                }}
              </div>
            </span>
          </span>
        </li>
      </div>
      <!-- <b-sidebar id="sidebar-viewstandard" bg-variant="white" right backdrop shadow>
           
          </b-sidebar> -->
    </div>
  </div>
</template>

<script>
import Vue from "vue";
import Tree from "./Tree.vue";
import vSelect from "vue-select";
import Ripple from "vue-ripple-directive";
import Treeselect from "@riophae/vue-treeselect";
import { ValidationProvider, ValidationObserver } from "vee-validate";
import { required } from "@validations";
// import the styles
import "@riophae/vue-treeselect/dist/vue-treeselect.css";
import {
  BButton,
  BSidebar,
  BDropdownItem,
  BDropdown,
  VBToggle,
  BFormTextarea,
  BFormSelect,
  BBadge,
} from "bootstrap-vue";
import editstandards from "./editstandards.vue";
export default {
  name: "Tree",
  props: {
    node: Array,
    type: String,
    ispublic: Boolean,
    index: {
      default: "",
    },
    subsetid: String,
  },
  data: () => ({
    allControlsFamily: [],
    filteredFamily: [],
    inputShow: {},

    inputAdd: {},
    subControlName: "",
    subControlNumber: "",
    insertValue: {
      dataName: "",
      dataDescription: "",
      dataControlNumber: "",
    },
    insertControlFamilyValue: {
      ControlFamilyDataName: "",
      ControlFamilyDataDescription: "",
      ControlFamilyDataControlNumber: "",
    },
    subControlName: "",
    subControlNo: "",
    allStandards: [],
    familyId: "",
    showFmailyInput: false,
    hideControlFamily: false,
    selected: "Group",
    options: [
      { value: "Group", text: "Group" },
      { value: "Control", text: "Control" },
    ],
    ShowSubControl: {},
    subControlDetails: [],
    seletedControlOptions: [],
    selectedControl: [],
    controlData: [],
    tableDisplay: false,
    tableGroup: false,
    groupTableAdd: false,
    conid: "",
    enable: 1,
    disable: 0,
    subsetlist: [],
    subset: [],
    label_options: [],
  }),
  components: {
    Tree,
    BButton,
    BSidebar,
    editstandards,
    BFormTextarea,
    BFormSelect,
    vSelect,
    BDropdown,
    BDropdownItem,
    BBadge,
    Treeselect,
    ValidationProvider,
    ValidationObserver,
  },
  directives: {
    "b-toggle": VBToggle,
  },
  mounted() {

    this.bindEvents();
    this.familyId = this.$route.params.id;
    this.getSubset(this.familyId);
  },
  methods: {
    redirectToControlDetail(id){
      this.$router.push(`/Standard/${this.$route.params.id}/control/${id}`)
    },
    getSubset(id) {
      const options = {
        method: "GET",
        headers: {
          "content-type": "application/json",
          Accept: "application/json",
        },

        url: process.env.VUE_APP_ONECSF_API_URL + `/standard_subset/${id}`,
      };

      this.$http(options)
        .then((res) => {
          this.subsetlist = res.data.data;
          this.label_options = [];
          for (let [key, value] of Object.entries(this.subsetlist)) {
            let a = {
              id: value._id,
              label: value.name,
            };
            // console.log(res.data[key].profile.organization)
            this.label_options.push(a);
          }
        })
        .catch((err) => {});
    },
    addSubControlData(subControlData) {
      this.$root.$emit("addSubControl", subControlData);
    },
    addSubControl(data) {
      Vue.set(this.ShowSubControl, data._id, !this.ShowSubControl[data._id]);
    },
    bindEvents() {
      this.$root.$off("hideFamily").$on("hideFamily", (value) => {
        this.hideControlFamily = value;
      });
      this.$root
        .$off("addFamilyaData")
        .$on("addFamilyaData", (addFamilyaData) => {
          this.showFamilyData = addFamilyaData.showValue;
          this.familyId = addFamilyaData.id;
        });
      this.$root.$off("hideinput").$on("hideinput", (val) => {
        let self = this;
        if (val.insertData) {
          Vue.set(this.inputAdd, val.inputId, !this.inputAdd[val.inputId]);
        } else if (!val.insertData && !val.type) {

          Vue.set(this.inputShow, val.inputId, !this.inputShow[val.inputId]);
        } else if (val.type == "subcontrol") {

          Vue.set(this.inputShow, val.id, !this.inputShow[val.id]);
        } else {

          Vue.set(this.inputShow, val.inputId, !this.inputShow[val.inputId]);
        }
      });
    },
    tableListFamily() {},
    openSidebarview: function (editData) {
      this.qsandperiodic = editData;
      this.$emit("qsandperiodic", this.qsandperiodic);
      this.$root.$emit("bv::toggle::collapse", "sidebar-viewstandard");
    },
    getStandardClick(value, e) {

      this.conid = value._id;

      this.groupTableAdd = false;
      if (!e) {
        e = value.e;
        value = value.value;
      }
      this.$emit("treeData", { value, e });
    },
    deleteData(deleteData, index) {
      deleteData.index = index;
      this.$root.$emit("delete", deleteData);
    },
    editData(formData) {
      this.$emit("edit", formData);
    },
    showData(formData) {
      this.$emit("edit", formData);
    },

    cancelDetails(id, data) {
      if (data.childType == "Subcontrol") {
        this.insertValue.dataName = "";
        this.insertValue.dataControlNumber = "";
        Vue.set(this.inputShow, id, !this.inputShow[id]);

      } else {
        this.insertValue.dataDescription = "";
        this.insertValue.dataName = "";
        this.insertValue.dataControlNumber = "";
        Vue.set(this.inputShow, id, !this.inputShow[id]);
      }
    },
    addControlKey(id, data) {
      if (data.childType == "Subcontrol") {
        Vue.set(this.inputShow, id, !this.inputShow[id]);
      } else {
        this.groupTableAdd = true;
        Vue.set(this.inputShow, id, !this.inputShow[id]);
      }
    },
    addFamily(id) {
      Vue.set(this.inputAdd, id, !this.inputAdd[id]);
    },
    addDataFirstFam(val, index) {
      let insertData = {
        insertData: this.insertValue,
        indexValue: index,
        type: "tableFamilydata",
      };
      this.$root.$emit("tableFamily", insertData);
    },
    addData(dataVal, index) {
      // console.log(JSON.stringify(this.subset) + "subcontrol data");
      if (dataVal.childType == "Subcontrol") {
        const subDetails = {
          name: this.insertValue.dataName,
          control_no: this.insertValue.dataControlNumber,
          id: dataVal._id,
          indexValue: index,
          description: this.insertValue.dataDescription,
          standard_id: dataVal.standard_id,
          type: "Subcontrol",
          control_family_id: dataVal.control_family_id,
          parent_id: dataVal._id,
          subset_id: this.subset,
        };
        this.$root.$emit("addSubControl", subDetails);
      } else {
        let insertData = {
          datavalue: dataVal,
          insertData: this.insertValue,
          indexValue: index,
          subControl: this.subControlDetails,
          subset_id: this.subset,
        };
        this.$root.$emit("addControl", insertData);
      }
    },
  },
  beforeDestroy() {
    this.$off("delete");
    // EventBus.$off('saveData')
  },
};
</script>
<style lang="scss">
@import "@core/scss/vue/libs/vue-select.scss";

.node-tree {
  list-style-type: none;
  cursor: pointer;
}
.border {
  border-left: 1px solid #ebe9f1 !important;
  border-right: 1px solid #ebe9f1 !important;
}
.mb-right {
  margin-right: 5px;
}
.font-weight {
  font-weight: 500;
}

.margin {
  margin-left: 35px;
}

.font-15 {
  font-size: 15px;
}

.label {
  cursor: pointer;
}

.flex {
  display: flex;
}

.b-sidebar {
  width: 50% !important;
}
.table-view {
  display: flex;
  width: 100%;
  align-items: center;
  padding-bottom: 10px !important;
  padding-top: 10px !important;
  background-color: #f3f2f7 !important;
}
.table-view:first-child {
  padding-left: 20px !important;
  padding-right: 20px !important;
  border: 1px solid #dedbdb;
}
.padding-l-f {
  padding-left: 20px !important;
  padding-right: 20px !important;
}
.flex-display {
  display: flex;
}

.margin-right {
  margin-right: 10px;
}
.margin-right-align {
  display: flex;
  align-items: flex-end;
  justify-content: end;
}

.align-right {
  margin-left: 5px;
  display: flex;
  align-items: flex-end;
  justify-content: flex-end;
}

.input-data {
  margin-top: 10px;
  margin-bottom: 10px;
}
.font-style {
  font-weight: 600;
}
.width {
  width: 30%;
}

.align-center {
  justify-content: center;
  align-items: center;
}

.space-last {
  justify-content: space-between;
}

.align {
  display: inline-flex;
  width: 100%;
}

.mr-botm {
  margin-bottom: 10px;
}

.full-width {
  width: 100%;
}

.center {
  align-items: center;
}

.pointer {
  cursor: pointer;
}

.mr-bottom {
  margin-bottom: 10px;
}

.icon-box-new {
  width: 25px;
  height: 25px;
  padding: 5px;
  border-radius: 5px;
  align-items: center;
  justify-content: center;
  margin-right: 10px;
  display: inline-flex;
}

.mr-botm-10 {
  margin-bottom: 10px;
}

.padding-bottom {
  padding-bottom: 10px;
}

.padding-20 {
  padding: 10px 30px;
}

.text-primary-black {
  color: #000;
}

.no-Padding {
  padding: 0 1rem !important;
}

textarea.form-control {
  padding: 0 1rem !important;
}

.aPlign-center textarea.form-control {
  height: 38px !important;
}

.mr-btm-5 {
  margin-bottom: 5px !important;
}

.mr-btm-20 {
  margin-bottom: 20px !important;
}
.description-text {
  font-style: italic;
  font-size: 12px;
  margin-left: 30px;
  border-radius: 5px;
  padding: 5px;
  margin-bottom: 10px;
}
.list-style li {
  list-style: none;
  // background-color: red;
}
.table-listing-tree-view {
  border-left: 1px solid #dadada;
  border-right: 1px solid #dadada;
}
.table-listing-tree-view:last-child {
  border-bottom: 1px solid #dadada;
}

.list-view-sub {
  background-color: #5a6268 !important;
  padding: 8px;
  margin-top: 10px;
  color: #fff;
}
.data-wrap {
  background-color: #fff;
  border: none;
  margin-bottom: 2rem;
  box-shadow: 0 4px 24px 0 rgb(34 41 47 / 10%);
  padding: 20px;
}
.description-text-table {
  font-style: italic;
  font-size: 12px;
  margin-left: 15px;
  align-items: center;
  display: flex;
  // max-width: 300px;
}
.table-list {
  border-top: 1px solid #dadada;
  border-right: none;
  border-left: none;
  padding: 0px 20px 0px 20px;
  box-shadow: 0 4px 24px 0 rgb(34 41 47 / 10%);
  background-color: #fff;
  align-items: center;
}
.table-list:last-child {
  border-bottom: none;
}
.group-text {
  font-size: 12px;
  height: fit-content;
  padding: 5px;
  border-radius: 5px;
  color: #fff;
}
.width-33 {
  width: 25%;
}
.text-aligin-right {
  text-align: right;
}
.width-50 {
  width: 50% !important;
}
.custom-control-success {
  background-color: #28c76f;
}
.padding-btm {
  padding-bottom: 10px !important;
}
/* .tree > li:first-child {
  padding: 20px;
} */
.fullwidth {
  width: 100%;
}
.addGroup {
  padding: 4px;
  color: #fff;
  display: flex;
  align-items: center;
  justify-content: space-between;
  // width: fit-content;
  // border-radius: 5px ;
}
.align-right-table {
  display: flex;
  align-items: center;
}
.badge-control {
  background-color: #a1a0a7 !important;
}
.width-30 {
  width: 30%;
}
.width-10 {
  width: 20%;
}
.grid-container {
  display: grid;
  grid-template-columns: auto auto auto auto;
  padding: 20px;
  grid-gap: 10px;
  box-shadow: 0 4px 24px 0 rgb(34 41 47 / 10%);
  background-color: #fff;
}
.grid-item-button {
  align-items: center;
  justify-content: center;
  display: flex;
}
.hight-text {
  height: 38px !important;
}
</style>
