<template>
  <div class="p-2">
    <h4>Change Logs</h4>
    <app-timeline>
      <app-timeline-item
      v-for="log in  logs"
        :title="log.new_data"
        :subtitle="log.old_data"
        :time="log.date"
        variant="primary"
        :key="log"
      />
    </app-timeline>
  </div>
</template>
<script>
import AppTimeline from '@core/components/app-timeline/AppTimeline.vue'
import AppTimelineItem from '@core/components/app-timeline/AppTimelineItem.vue'

export default {
  components: {
    AppTimeline,
    AppTimelineItem,
  },
  props:{
    logs:Array,
  },
}
</script>

<style scoped>
.timeline-item h6::after {
    content: 'new data';
}
</style>
