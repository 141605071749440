<template>
  <div class="editContainer">
    <div class="font-weight mr-bottom">Edit Details</div>
    <div>
      <label>Control Number</label>

      <input
        v-model="controlNo"
        placeholder="control no"
        class="form-control input-sm flex input-data"
        :disabled="isPublic"
      />
      <label>Name</label>
      <input
        v-model="name"
        placeholder="name"
        class="form-control input-sm flex margin-right input-data"
        :disabled="isPublic"
      />

      <label>Description</label>
      <b-form-textarea
        rows="2"
        v-model="description"
        placeholder="description"
        class="mr-bottom"
        :disabled="isPublic"
      />
      <div
        v-if="
          !showMapping && editdata.control_family_id && editdata.type != 'Group'
        "
      >
        <label>Onecsf control</label>
        <v-select
          class="mr-bottom"
          v-model="selectedControl"
          placeholder="Search"
          label="text"
          multiple
          :options="seletedControlOptions"
          @search="getSearchControls"
          :disabled="isPublic"
          :class="isPublic ? disableColor : ''"
        />
      </div>
      <label for="">Subset</label>
      <treeselect
        v-model="subset"
        :multiple="true"
        :options="label_options"
        class="mb-1"
        :disabled="isPublic"
        @deselect="deselect(node, instanceId)"
        @select="selectedsubset()"
      >
      </treeselect>
      <div>
        <button
          class="btn btn-primary btn-md margin-right"
          @click="updateDetails()"
          :disabled="isPublic"
        >
          Update
        </button>
        <button
          class="btn btn-md btn-secondary"
          @click="cancel"
          :disabled="isPublic"
        >
          cancel
        </button>
      </div>
      <b-tabs
        class="mt-1"
        v-if="
          editdata.type == 'Control' ||
          editdata.type == 'Subcontrol' ||
          editdata.type == 'Group'
        "
      >
        <b-tab title="Assessment Questions" active>
          <div
            v-if="
              editdata.type == 'Control' ||
              editdata.type == 'Subcontrol' ||
              editdata.type == 'Group'
            "
          >
            <div class="font-weight mr-bottom mt-1">
              Add Assessment Questions
            </div>
            <div class="row" style="margin-left: 0px; margin-right: 0px">
              <label for="">Question</label>
              <textarea
                name=""
                id=""
                cols="30"
                rows="3"
                class="form-control"
                v-model="question"
                :disabled="isPublic"
              ></textarea>
              <label for="">What response is considered as gap?</label>
              <select
                name=""
                id=""
                v-model="answer"
                class="form-control mt-1 mb-1"
                :disabled="isPublic"
              >
                <option
                  :value="response.value"
                  v-for="response in responseset"
                  :key="response"
                >
                  {{ response.title }}
                </option>
              </select>
              <label for="">Tags</label>
               <v-select
                v-model="selectedTags"
                taggable
                multiple
                label="label"
                style="width: 100%"
                :options="tags_options"
                @search="fetchTags"
                autocomplete
                placeholder="Search with Tags"
                :reduce="(loc) => loc.label"
                class="mb-1"
              />
              <b-form-checkbox
                v-model="alwayscheck"
                plain
                class=""
                :disabled="isPublic"
              >
                Always Applicable
              </b-form-checkbox>
              <b-form-checkbox
                v-model="checkevidence"
                plain
                class=""
                :disabled="isPublic"
              >
                Evidence Required
              </b-form-checkbox>
              <br />
              <div class="col-12">
                <button
                  class="btn btn-primary btn-sm mt-1 float-right"
                  @click="addQuestion(editdata._id)"
                  :disabled="isPublic"
                >
                  Add +
                </button>
              </div>
            </div>

            <div class="row" style="margin-left: 0px; margin-right: 0px">
              <div class="col-12">
                <p class="mt-1"><b>Questions</b></p>
              </div>
            </div>

            <div class="questions">
              <div
                class="row"
                style="margin-left: 0px; margin-right: 0px"
                v-for="(qs, index) in this.questions"
              >
                <div class="col-10">
                  <p style="font-size: 15px">{{ qs.question }}</p>
                </div>
                <div class="col-2">
                  <span class="icon-box-new" @click="openEditQsModel(qs._id)">
                    <feather-icon
                      class="pointer rotate"
                      icon="Edit2Icon"
                      size="20"
                    />
                  </span>
                  <span class="icon-box-new" @click="openDeleteQsModel(qs._id)">
                    <feather-icon
                      class="text-danger pointer rotate"
                      icon="Trash2Icon"
                      size="20"
                    />
                  </span>
                </div>
              </div>
            </div>
          </div>
        </b-tab>
        <b-tab title="Periodic Activity">
          <b-form-group v-slot="{ ariaDescribedby }">
            <b-form-checkbox-group
              v-model="selectedPeriod"
              :options="selectedPeriodOptions"
              value-field="item"
              text-field="name"
              disabled-field="notEnabled"
              class="demo-inline-spacing"
              :aria-describedby="ariaDescribedby"
            />
          </b-form-group>
          <button
            class="btn btn-primary btn-sm mt-1"
            @click="updatePeriodDetails()"
            :disabled="isPublic"
          >
            Update Activity
          </button>
        </b-tab>
      </b-tabs>
    </div>
    <b-modal ref="modal-edit-qs" title="Edit Question " hide-footer>
      <textarea
        name=""
        id=""
        cols="30"
        rows="3"
        v-model="qname"
        class="form-control"
        :disabled="isPublic"
      ></textarea>
      <label for="">What response is considered as gap?</label>
      <select
        name=""
        id=""
        v-model="ans"
        class="form-control mt-1"
        :disabled="isPublic"
      >
        <option
          :value="response.value"
          v-for="response in responseset"
          :key="response"
        >
          {{ response.title }}
        </option>
      </select>
      <label for="">Tags</label>
       <v-select
        :value="temp_label_options"
        v-model="temp_label_options"
        multiple
        label="label"
        style="width: 100%"
        :options="tags_options"
        @search="fetchTags"
        placeholder="Search with Tags"
        class="mb-1"
        taggable
        :reduce="(loc) => loc.label"
      />
      <b-form-checkbox
        v-model="checkalways"
        plain
        class="mt-1 mr-1"
        :disabled="isPublic"
      >
        Always Applicable
      </b-form-checkbox>

      <b-form-checkbox
        v-model="evidencecheck"
        plain
        class="mt-1"
        :disabled="isPublic"
      >
        Evidence Required
      </b-form-checkbox>
      <button
        class="btn btn-primary mt-1"
        @click="updateQuestion(qsid)"
        :disabled="isPublic"
      >
        Update Question
      </button>
    </b-modal>
    <b-modal ref="modal-delete-qs" title="Are you sure to delete? " hide-footer>
      <button
        class="btn btn-danger"
        @click="deleteQuestion(qsid)"
        :disabled="isPublic"
      >
        Delete Question
      </button>
    </b-modal>
    <b-modal ref="modal-remove-subset" title="Are you sure to remove? ">
      <p>Subset will be removed from all child controls. !</p>
    </b-modal>
  </div>
</template>

<script>
import Vue from "vue";
import {
  BFormTextarea,
  BFormCheckbox,
  BFormCheckboxGroup,
  BFormGroup,
} from "bootstrap-vue";
import ToastificationContent from "@core/components/toastification/ToastificationContent.vue";
import { BTabs, BTab } from "bootstrap-vue";
import vSelect from "vue-select";
import Treeselect from "@riophae/vue-treeselect";
import { VueAutosuggest } from "vue-autosuggest";
// import the styles
import "@riophae/vue-treeselect/dist/vue-treeselect.css";
export default {
  data: () => ({
    name: "",
    description: "",
    standardId: "",
    controlNo: "",
    seletedControlOptions: [],
    selectedControl: [],
    controlData: [],
    showMapping: false,
    standardType: "",
    allPrimaryControls: [],
    onecsf_control_id: [],
    controlFamilyID: "",
    parentId: "",
    question: "",
    answer: "",
    questions: [],
    alwayscheck: false,
    qsid: "",
    questiondetails: [],
    qname: "",
    ans: "",
    checkalways: "",
    activityData: [],
    selectedPeriodOptions: [],
    selectedPeriod: [],
    activitylist: [],
    evidencecheck: "",
    responsibility: "VM team",
    options: [
      { item: "A", name: "Option A" },
      { item: "B", name: "Option B" },
      { item: "D", name: "Option C", notEnabled: true },
      { item: { d: 1 }, name: "Option D" },
    ],
    controlid: "",
    isPublic: false,
    subset: [],
    subsetlist: [],
    label_options: [],
    draftStandardId: "",
    draftstatus: 0,
    tags: [],
    tags_options: [],
    selectedTags: [],
    temp_label_options: [],
  }),
  computed:{
    skin(){
      return this.$store.state.appConfig.layout.skin
    }
  },
  components: {
    BFormTextarea,
    vSelect,
    BFormCheckbox,
    BTabs,
    BTab,
    BFormCheckboxGroup,
    Treeselect,
    BFormGroup,
    VueAutosuggest,
  },
  props: {
    editdata: Object,
    standards: Array,
    responseset: Array,
    closeSidebar: {
      type: Function,
      required: true,
    },
    reloadMasterComponent: {
      type: Function,
      required: true,
    },
  },
  watch: {
    editdata() {
      this.bindEvents();
      this.getActivitylist();
      this.load();
      this.getAllPrimaryControls();
      this.getQuestions(this.editdata._id);
    },
  },
  mounted() {
    this.bindEvents();
    this.getActivitylist();
    this.getSubset(this.$route.params.id);
    this.fetchTags(this.$route.params.id);
  },
  methods: {
    deselect(node, instanceId) {
      this.$toast({
        component: ToastificationContent,
        props: {
          title: "Subset will be removed from all child controls",
          icon: "EditIcon",
          variant: "warning",
        },
      });
    },
    selectedsubset() {
      this.$toast({
        component: ToastificationContent,
        props: {
          title: "Subset applicable to all child controls",
          icon: "EditIcon",
          variant: "warning",
        },
      });
    },
    load() {
      this.getQuestions(this.editdata._id);
      this.getSubset(this.$route.params.id);
    },
    fetchTags(stdid) {
      const options = {
        method: "GET",
        headers: {
          "content-type": "application/json",
          Accept: "application/json",
        },
        url:
          process.env.VUE_APP_ONECSF_API_URL +
          `/assessment-question-tags?standard_id=${stdid}`,
      };
      this.$http(options)
        .then((res) => {
          this.tags = res.data.data.data;
          this.tags_options = [];
          for (let [key, value] of Object.entries(this.tags)) {
            let a = {
              id: value._id,
              label: value.title,
            };
            this.tags_options.push(a);
          }
        })
        .catch((err) => {});
    },
    getSubset(id) {
      const options = {
        method: "GET",
        headers: {
          "content-type": "application/json",
          Accept: "application/json",
        },

        url: process.env.VUE_APP_ONECSF_API_URL + `/standard_subset/${id}`,
      };

      this.$http(options)
        .then((res) => {
          this.subsetlist = res.data.data;
          this.label_options = [];
          for (let [key, value] of Object.entries(this.subsetlist)) {
            let a = {
              id: value._id,
              label: value.name,
            };
            this.label_options.push(a);
          }
        })
        .catch((err) => {});
    },
    openEditQsModel(index) {
      this.qsid = index;
      this.getQuestionEdit(index);
      this.$refs["modal-edit-qs"].show();
    },
    openDeleteQsModel(index) {
      this.qsid = index;
      this.$refs["modal-delete-qs"].show();
    },
    updateQuestion(qsid) {
      const data = {
        question: this.qname,
        answer: this.ans,
        always_applicable: this.checkalways ? 1 : 0,
        evidence_rqd: this.evidencecheck ? 1 : 0,
        responsibility: this.responsibility,
        tags: this.temp_label_options,
      };

      const options = {
        method: "PUT",
        data: data,
        headers: { "content-type": "application/json" },
        url:
          process.env.VUE_APP_ONECSF_API_URL +
          `/assessment/${this.editdata._id}/${qsid}`,
      };

      this.$http(options)
        .then((res) => {
          this.reloadMasterComponent();
          this.getQuestions(res.data.data._id);
          this.$refs["modal-edit-qs"].hide();
          if (res.data.status == "error") {
            this.$toast({
              component: ToastificationContent,
              props: {
                title: res.data.message,
                icon: "EditIcon",
                variant: "danger",
              },
            });
          } else {
            this.$toast({
              component: ToastificationContent,
              props: {
                title: res.data.message,
                icon: "EditIcon",
                variant: "success",
              },
            });
          }

          console.log(res.data);
        })
        .catch((err) => {
          console.log(err);
        });
    },
    getQuestionEdit(qsid) {
      const options = {
        method: "GET",
        headers: { "content-type": "application/json" },
        url:
          process.env.VUE_APP_ONECSF_API_URL +
          `/assessment/${this.editdata._id}/${qsid}`,
      };
      this.$http(options)
        .then((res) => {
          this.questiondetails = res.data.data;
          this.qname = res.data.data.question;
          this.ans = res.data.data.answer;
          this.checkalways =
            res.data.data.always_applicable == 1 ? true : false;
          (this.alwayscheck = res.data.data.always_applicable ? 1 : 0),
            (this.evidencecheck = res.data.data.evidence_rqd ? true : false),
            console.log(res.data);
          this.temp_label_options = [];
          for (let [key, value] of Object.entries(res.data.data.tags)) {
            let a = {
              // id: value._id,
              label: value.title,
            };
            // console.log(res.data[key].profile.organization)
            this.temp_label_options.push(value.title);
          }
        })
        .catch((err) => {
          console.log(err);
        });
    },
    deleteQuestion(qsid) {
      const options = {
        method: "DELETE",
        headers: { "content-type": "application/json" },
        url:
          process.env.VUE_APP_ONECSF_API_URL +
          `/assessment/${this.editdata._id}/${qsid}`,
      };
      this.$http(options)
        .then((res) => {
          // this.closeSidebar();
          this.reloadMasterComponent();
          this.$forceUpdate();
          if (this.editdata.type == "Control") {
            this.getQuestions(this.editdata._id);
          } else {
            this.getQuestions(this.editdata._id);
          }

          this.$refs["modal-delete-qs"].hide();
          if (res.data.status == "error") {
            this.$toast({
              component: ToastificationContent,
              props: {
                title: res.data.message,
                icon: "EditIcon",
                variant: "danger",
              },
            });
          } else {
            this.$toast({
              component: ToastificationContent,
              props: {
                title: res.data.message,
                icon: "EditIcon",
                variant: "success",
              },
            });
          }
          console.log(res.data);
        })
        .catch((err) => {
          console.log(err);
        });
    },

    addQuestion(id) {
      const data = {
        question: this.question,
        answer: this.answer,
        always_applicable: this.alwayscheck ? 1 : 0,
        evidence_rqd: this.checkevidence ? 1 : 0,
        tags: this.selectedTags,
        // responsibility: this.responsibility,
      };
      const options = {
        method: "POST",
        data: data,
        headers: { "content-type": "application/json" },
        url: process.env.VUE_APP_ONECSF_API_URL + `/assessment/${id}`,
      };
      this.$http(options)
        .then((res) => {
          // this.closeSidebar();
          this.question = "";
          this.answer = "";
          this.selectedTags = [];
          this.alwayscheck = false;
          this.checkevidence = false;
          this.getQuestions(res.data.data._id);
          this.draftstatus = res.data.data.draft_status;
          this.draftStandardId =
            res.data.data.draft_status == 1 ? res.data.data._id : "";
          this.reloadMasterComponent();
          this.$forceUpdate();
          if (res.data.status == "error") {
            this.$toast({
              component: ToastificationContent,
              props: {
                title: res.data.message,
                icon: "EditIcon",
                variant: "danger",
              },
            });
          } else {
            this.$toast({
              component: ToastificationContent,
              props: {
                title: res.data.message,
                icon: "EditIcon",
                variant: "success",
              },
            });
          }
          console.log(res.data);
        })
        .catch((err) => {
          console.log(err);
        });
    },
    getQuestions(id) {
      const options = {
        method: "GET",
        headers: { "content-type": "application/json" },
        url: process.env.VUE_APP_ONECSF_API_URL + `/assessment/${id}`,
      };
      this.$http(options)
        .then((res) => {
          this.questions = res.data.data;
          console.log(res.data);
        })
        .catch((err) => {
          console.log(err);
        });
    },
    bindEvents() {
      this.$root.$off("editValdata").$on("editValdata", (data, ispublic) => {
        console.log(JSON.stringify(data) + "data from edit val");
        this.standardId = data._id;
        this.name = data.name;
        this.description = data.description;
        this.controlid = data.control_id;
        this.isPublic = ispublic;
        this.idx = data.index;
        this.subset = data.subset_id;
        this.draftstatus = data.draft_status;
        if (data.type == "Subcontrol") {
          this.controlNo = data.control_no;
        } else {
          this.controlNo = data.control_no;
        }

        this.selectedPeriod = data.period_act_id;
        // alert(JSON.stringify(data.name))
        console.log(data.period_act_id);
        this.controlFamilyID = data.control_family_id;
        this.parentId = data.parent_id;
        if (data.standard_id) {
          const options = {
            method: "GET",
            headers: { "content-type": "application/json" },
            url:
              process.env.VUE_APP_ONECSF_API_URL +
              `/standards/${data.standard_id}`,
          };
          this.$http(options)
            .then((res) => {
              this.onecsf_control_id = data.onecsf_control_id;
              this.standardId = res.data.data.standard._id;
              (this.showMapping =
                res.data.data.standard.is_primary == "1" ? true : false),
                (this.standardType = res.data.data.standard.type);
              this.getAllPrimaryControls();
            })
            .catch((err) => {
              console.log(err);
            });
        }
      });
    },

    getAllPrimaryControls() {
      const options = {
        method: "GET",
        headers: { "content-type": "application/json" },
        url:
          process.env.VUE_APP_ONECSF_API_URL +
          `/control/primary?type=${this.standardType}`,
      };
      this.$http(options)
        .then((res) => {
          this.allPrimaryControls = res.data.data;
          console.log(JSON.stringify(res.data.data) + "from primary");
          console.log(this.onecsf_control_id && this.onecsf_control_id.length);
          if (this.onecsf_control_id && this.onecsf_control_id.length) {
            console.log("hai helloo");
            this.selectedControl = [];
            this.onecsf_control_id.forEach((el) => {
              this.allPrimaryControls.filter((ele) => {
                if (el == ele._id) {
                  let a = {
                    value: ele._id,
                    text: ele.name,
                  };
                  this.selectedControl.push(a);
                }
              });
            });
          }
        })
        .catch((err) => {
          console.log(err);
        });
    },
    getStandards(id) {
      const options = {
        method: "GET",
        headers: { "content-type": "application/json" },
        url: process.env.VUE_APP_ONECSF_API_URL + `/standards/${id}`,
      };
      this.$http(options)
        .then((res) => {
          // console.log(res.data.data + 'hala')
          this.standardId = res.data.data.standard._id;
          (this.showMapping =
            res.data.data.standard.is_primary == "1" ? true : false),
            (this.standardType = res.data.data.standard.type);
        })
        .catch((err) => {
          console.log(err);
        });
    },
    cancel() {
      this.standardId = "";
      this.name = "";
      this.description = "";
      this.controlNo = "";
      this.closeSidebar();
    },
    getSearchControls(name) {
      const options = {
        method: "GET",
        headers: { "content-type": "application/json" },
        url:
          process.env.VUE_APP_ONECSF_API_URL +
          `/control/primary?type=${this.standardType}&name=${name}`,
      };
      this.$http(options)
        .then((res) => {
          this.controlData = res.data.data;
          this.seletedControlOptions = [];
          // this.selectedControl = [];
          for (let [key, value] of Object.entries(this.controlData)) {
            let a = {
              value: value._id,
              text: value.name,
            };
            this.seletedControlOptions.push(a);
          }
        })
        .catch((err) => {
          console.log(err);
        });
    },
    getActivitylist() {
      const options = {
        method: "GET",

        headers: { "content-type": "application/json" },
        url: process.env.VUE_APP_ONECSF_API_URL + `/periodic_acts`,
      };
      this.$http(options)
        .then((res) => {
          this.activitylist = res.data.data.data;
          this.selectedPeriodOptions = [];
          // this.selectedPeriod = [];
          for (let [key, value] of Object.entries(this.activitylist)) {
            let a = {
              item: value._id,
              name: value.activity,
            };
            console.log(a);
            this.selectedPeriodOptions.push(a);
          }

          console.log(res.data);
        })
        .catch((err) => {
          console.log(err);
        });
    },
    getPeriodicActivity(name) {
      const options = {
        method: "GET",
        headers: { "content-type": "application/json" },
        url:
          process.env.VUE_APP_ONECSF_API_URL +
          `/periodic_act/search?activity=${name}`,
      };
      this.$http(options)
        .then((res) => {
          this.activityData = res.data.data;
          this.selectedPeriodOptions = [];
          // this.selectedPeriod = [];
          for (let [key, value] of Object.entries(this.activityData)) {
            let a = {
              value: value._id,
              text: value.activity,
            };
            console.log(a);
            this.selectedPeriodOptions.push(a);
          }
        })
        .catch((err) => {
          console.log(err);
        });
    },
    updateDetails() {
      let urlVal;
      let data = {};
      let controlUpdate = false;
      if (!this.editdata.control_family_id) {
        urlVal = `cl_families`;
      } else if (this.editdata.control_family_id) {
        urlVal = `controls`;
        controlUpdate = true;
      }

      if (controlUpdate) {
        const selectControlIDS = [];
        this.selectedControl.forEach((ele) => {
          selectControlIDS.push(ele.value);
        });

        data = {
          name: this.name,
          description: this.description,
          standard_id: this.standardId,
          control_no: this.controlNo,
          onecsf_control_id: selectControlIDS,
          control_family_id: this.controlFamilyID,
          parent_id: this.parentId,
          subset_id: this.subset,
          // period_act_id: this.selectPeriodIDS,
        };
      } else {
        data = {
          name: this.name,
          description: this.description,
          standard_id: this.standardId,
          control_no: this.controlNo,
          subset_id: this.subset,
          // period_act_id: this.selectPeriodIDS,
        };
      }

      const options = {
        method: "PUT",
        data: data,
        headers: { "content-type": "application/json" },
        url:
          process.env.VUE_APP_ONECSF_API_URL +
          `/${urlVal}/${this.editdata._id}`,
      };
      this.$http(options)
        .then((res) => {
          this.$emit("hide", this.hideVal);
          // this.$route.go();
          console.log(res);
        })
        .catch((err) => {
          console.log(err);
        })
        .finally(() => {
          this.closeSidebar();
          this.reloadMasterComponent();
        });
    },
    updatePeriodDetails() {
      let urlVal;
      let data = {};
      let controlUpdate = false;
      if (!this.editdata.control_family_id) {
        urlVal = `cl_families`;
      } else if (this.editdata.control_family_id) {
        urlVal = `controls`;
        controlUpdate = true;
      }

      if (controlUpdate) {
        const selectControlIDS = [];
        this.selectedControl.forEach((ele) => {
          selectControlIDS.push(ele.value);
        });

        data = {
          name: this.name,
          description: this.description,
          standard_id: this.standardId,
          control_no: this.controlNo,
          onecsf_control_id: selectControlIDS,
          control_family_id: this.controlFamilyID,
          parent_id: this.parentId,
          period_act_id: this.selectedPeriod,
        };
      } else {
        data = {
          name: this.name,
          description: this.description,
          standard_id: this.standardId,
          no: this.controlNo,
          period_act_id: this.selectedPeriod,
        };
      }

      const options = {
        method: "PUT",
        data: data,
        headers: { "content-type": "application/json" },
        url:
          process.env.VUE_APP_ONECSF_API_URL +
          `/${urlVal}/${this.editdata._id}`,
      };
      this.$http(options)
        .then((res) => {
          this.$emit("hide", this.hideVal);
          console.log(res);
        })
        .catch((err) => {
          console.log(err);
        })
        .finally(() => {
          this.closeSidebar();
          this.reloadMasterComponent();
        });
    },
    getStandardsFamily(id) {
      const options = {
        method: "GET",
        headers: { "content-type": "application/json" },
        url: process.env.VUE_APP_ONECSF_API_URL + `/cl_families/${id}`,
      };
      this.$http(options)
        .then((res) => {
          this.description = res.data.data.control.description;
          this.controlNo = res.data.data.control.control_no;
        })
        .catch((err) => {
          console.log(err);
        });
    },
  },
};
</script>

<style lang="scss">
@import "@core/scss/vue/libs/vue-select.scss";
/* Light Mode (default styles or slight customization) */


/* Dark Mode */
.dark-layout{

  .vue-treeselect__menu{
    background-color: #283046;
  }
  
  /* Override necessary styles */
  .vue-treeselect__control{
    background-color: #283046;
    border-color: #404656;
  }
  .vue-treeselect__multi-value-item{
    background-color: rgba(0, 207, 232, 0.12);
  }
  .vue-treeselect--disabled .vue-treeselect__multi-value-item{
    background-color: rgba(0, 207, 232, 0.12);
    border-color: #404656;
    color:#039be5 !important;
  }
  .treeselect .vue-treeselect__option--disabled,
.treeselect .vue-treeselect__input-container--disabled,
.treeselect .vue-treeselect__control--disabled {
  opacity: 1 !important; /* Make sure the opacity remains normal */
  cursor: default !important; /* Prevent the not-allowed cursor */
  background-color: inherit !important; /* Keep background color unchanged */
  color:#039be5 !important; /* Keep text color unchanged */
}
}

textarea.form-control {
  height: auto !important;
}
.editContainer {
  padding: 20px;
}
.mr-bottom {
  margin-bottom: 10px;
}

/* .vs__selected {
  color: #000 !important;
} */
</style>
